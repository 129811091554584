import React, { useEffect, useState } from 'react';
import { 
  Row, 
  Col, 
  Image, 
  Grid, 
  Input, 
  Button,
  Spin
} from 'antd';
import axios from 'axios';
import { SESSION_STORAGE_KEYS } from '../../../../constants';

const { useBreakpoint } = Grid;

interface AboutProps {}

const aboutPage = {
  title: 'About',
  description: `
    <p>Hallo, and welcome to my site. My name is Ikeem Wilson, and I am a United States citizen who is currently
    looking for work in the Netherlands. I am a software developer who is passionate about building software
    that is scalable, maintainable, and testable. I love learning new technologies and applying them to solve
    real-world problems.</p>
    <p><b>Why the Netherlands?</b> My wife and I visited the Netherlands in 2014, and we fell in love with the
    country. We love the people, the culture, and the way of life. We have been back to visit twice since then,
    and we have decided that we would like to live there. I am currently learning Dutch, and I have been
    looking for a job there since September of 2022.</p>
    <p>Thank you for reading, and I hope you have a wonderful day. Tot ziens!</p>`,
  keywords: 'about, page',
  image:{
    src: 'https://interactivecveast2.blob.core.windows.net/photos/Me.jpg',
    fallback: 'https://interactivecveast2.blob.core.windows.net/photos/error.png'
  },
  contactInfo: [
    {
      key: 'cell',
      icon: 'phone',
      value: '+1 (612) 432-0424'
    },
    {
      key: 'whatsapp',
      icon: 'whatsapp',
      value: '+1 (612) 432-0424'
    },
    {
      key: 'email',
      icon: 'mail',
      value: 'ikeem_wilson@yahoo.com'
    },
    {
      key: 'linkedin',
      icon: 'linkedin',
      value: 'https://www.linkedin.com/in/ike-wilson/'
    }
  ]
}

export const About: React.FC<AboutProps> = (props) => {
  const screens = useBreakpoint();
  const [promptText, setPromptText] = useState<string>('');
  const [responseGPT, setResponseGPT] = useState<string>('');
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
  const maxCharacters = 1000;
  const remainingCharacters = maxCharacters - promptText.length;

  useEffect(() => {
    // If not one, generate guid and save to session storage
    const chatContextId = sessionStorage.getItem(SESSION_STORAGE_KEYS.chatContextId);
    if (!chatContextId) {
      const guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
      sessionStorage.setItem(SESSION_STORAGE_KEYS.chatContextId, guid());
    }
  }, []);

  const processPrompt = async (prompt: string) => {
    setLoadingResponse(true);
    setResponseGPT(prevResponse => prevResponse ? `${prevResponse}<br/><div style="text-align: right; font-weight: 500">${promptText}</div>` : `<div style="text-align: right; font-weight: 500">${promptText}</div>`);
    try {
      const response = await axios.post(
        'https://open-ai-wilsonsoft.azurewebsites.net/api/OpenAi/chatContext', `{
        "chatContextId": "${sessionStorage.getItem(SESSION_STORAGE_KEYS.chatContextId)}",
        "prompt": "${promptText}"
      }`);
      setLoadingResponse(false);
      setResponseText(response.data.completion[0].message.content);
    } catch (error) {
      console.log('ERROR', error);
      setLoadingResponse(false);
      setResponseText(`<div style="color: red">I had a problem processing a response, please try again.</div>`);
    }
  }

  const setResponseText = (responseText: string) => {
    if (!responseText) return;

    const typeText = (text: string | any[], index = 0) => {
      if (index < text.length) {
        setResponseGPT(prevResponse => prevResponse ? `${prevResponse}${text[index]}` : `${text[index]}`);
        setTimeout(() => typeText(text, index + 1), 30); // Adjust 50 to control typing speed
      }
    }
    
    setTimeout(() => typeText(`<div style="text-align: left">${responseText}</div>`), 50);
    setPromptText('');
  }

  const mapContactInfo = () => {
    return aboutPage.contactInfo.map((contact) => {
      return (
        <Row key={contact.key} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <b>{contact.key}:&nbsp;</b>
            {contact.value}
        </Row>
      )
    })
  }

  const mapContactInfoCellPhone = () => {
    return aboutPage.contactInfo.map((contact) => {
      return (
        <Row key={contact.key}>
          <Col span={24} style={{textAlign: 'left'}}>
            <b>{contact.key}:&nbsp;</b>
            {contact.value}
          </Col>
        </Row>
      )
    })
  }

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Ensure value doesn't exceed the max characters limit
    if (inputValue.length <= maxCharacters) {
      setPromptText(inputValue);
    }
  }

  const calculateCharacterLimitColor = (remaining: number) => {
    // Calculate the intensity of red based on remaining characters
    const redIntensity = Math.min(255, Math.max(0, Math.floor((1 - remaining / maxCharacters) * 255)));
    return `rgb(${redIntensity}, 0, 0)`;
  }

  const mapAboutContent = () => {
    if (screens.md) {
      return <> 
        <Row>
          <Col span={6} offset={3}>
            <Row>
              <Col span={14} offset={5} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Image width={200} src={aboutPage.image.src} fallback={aboutPage.image.fallback}/>
              </Col>
            </Row>
            {mapContactInfo()}
          </Col>
          <Col span={12}
          >
            <h1 style={{padding: '0px 10px'}}>{aboutPage.title}</h1>
            <div style={{padding: '0px 10px'}} dangerouslySetInnerHTML={{__html: aboutPage.description}}/>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={9}>
            <div style={{padding: '0px 10px'}}>Have more questions? Just ask me: <div style={{ fontSize: '10px'}}>{`(powered by OpenAI)`}</div></div>
            <div style={{padding: '0px 10px'}}>
              <div dangerouslySetInnerHTML={{ __html: responseGPT}} style={{ border: '1px solid #ddd', padding: '10px', minHeight: '95px'}} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={9}>
            <div style={{padding: '10px'}}>
              <Input value={promptText} placeholder={'What would you like to know?'} onChange={handleInputTextChange} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    processPrompt(promptText);
                  }
                }}
                maxLength={maxCharacters}
                disabled={loadingResponse}
              />
            </div>
            <div 
              style={{ 
                padding: '0px 10px', textAlign: 'right', marginTop: '2px',
                color: calculateCharacterLimitColor(remainingCharacters)
              }}
            >
              <span>{maxCharacters - promptText.length} characters remaining</span>
            </div>
            <div style={{padding: '0px 10px'}}>
              <Button onClick={() => processPrompt(promptText)}>
                {loadingResponse ? <Spin/> : `Send`}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    } else {
      return <>
        <Row>
          <Col span={20} offset={2}>
            <Image width={200} src={aboutPage.image.src} fallback={aboutPage.image.fallback}/>
            {mapContactInfoCellPhone()}
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <h1>{aboutPage.title}</h1>
            <div dangerouslySetInnerHTML={{__html: aboutPage.description}}/>
            
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <div>Have more questions? Just ask me: <div style={{ fontSize: '10px'}}>{`(powered by OpenAI)`}</div></div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: responseGPT}} style={{ border: '1px solid #ddd', padding: '10px', minHeight: '95px'}} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <div style={{padding: '10px 0px'}}><Input value={promptText} placeholder={'What would you like to know?'} onChange={(e) => setPromptText(e.target.value)} /></div>
            <div>
              <Button onClick={() => processPrompt(promptText)}>
                {loadingResponse ? <Spin/> : `Send`}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col
            xs={{span: 22, offset: 1}}
            md={{span: 20, offset: 2}}
            lg={{span: 18, offset: 3}}
            style={{height: '5px'}}
          >
          </Col>
        </Row>
        {mapAboutContent()}
        <Row>
          <Col
            xs={{span: 22, offset: 1}}
            md={{span: 20, offset: 2}}
            lg={{span: 18, offset: 3}}
            style={{height: '50px'}}
          >
          </Col>
        </Row>
      </Col>
    </Row>
  )
}